import React from "react";
import Typography from "@mui/material/Typography";

function SnaggingPrivacyPolicy() {
  return (
    <>
      <Typography sx={{ fontSize: "1.1rem", fontWeight: 500, mt: 2, mb: 1 }}>
        Who we are
      </Typography>
      In this privacy policy references to ‘we, us and our’ are to New Build
      Inspections. References to ‘our Website’ or ‘the Website’ are to
      www.newbuildinspections.com
      <Typography sx={{ fontSize: "1.1rem", fontWeight: 500, mt: 2, mb: 1 }}>
        What information we collect and how
      </Typography>
      <Typography>
        The information we collect via the Website may include.
      </Typography>
      <ol>
        <li>
          Any personal details you knowingly provide us with through forms and
          our email, such as name, address, telephone number etc.
        </li>
        <li>
          Under no circumstances will we hold sensitive payment details such as
          your card number, expiry date and security code. All transactions are
          handled through our accredited payment bureau, Worldpay. For more
          information on Worldpays privacy policy please refer to
          http://www.worldpay.co.uk/about/index.php?go=privacy
        </li>
        <li>
          Your preferences and use of email updates, recorded by emails we send
          you (if you select to receive email updates on products and offers).
        </li>
        <li>
          Your IP Address, this is a string of numbers unique to your computer
          that is recorded by our web server when you request any page or
          component on the Website. This information is used to monitor your
          usage of the Website.
        </li>
        <li>
          Data recorded by the Website which allows us to recognise you and your
          preferred settings, this saves you from re-entering information on
          return visits to the site. Such data is recorded locally on your
          computer through the use of cookies. Most browsers can be programmed
          to reject, or warn you before downloading cookies, information
          regarding this may be found in your browsers help facility.
        </li>
      </ol>
      <Typography sx={{ fontSize: "1.1rem", fontWeight: 500, mt: 2, mb: 1 }}>
        What we do with your information
      </Typography>
      <Typography>
        Any personal information we collect from this website will be used in
        accordance with the Data Protection Act 1998 and other applicable laws.
        The details we collect will be used:
      </Typography>
      <ol>
        <li>
          To process your order, to provide after sales service (we may pass
          your details to another organisation to supply/deliver products or
          services you have purchased and/or to provide after-sales service);
        </li>
        <li>
          In certain cases we may use your email address to send you information
          on our other products and services. In such a case you will be offered
          the option to opt in/out before completing your purchase. We may need
          to pass the information we collect to other companies for
          administrative purposes. We may use third parties to carry out certain
          activities, such as processing and sorting data, monitoring how
          customers use the Website and issuing our e-mails for us. Third
          parties will not be allowed to use your personal information for their
          own purposes.
        </li>
      </ol>
      <Typography sx={{ fontSize: "1.1rem", fontWeight: 500, mt: 2, mb: 1 }}>
        Your Rights
      </Typography>
      <Typography>
        You have the right to request a copy of any information that we
        currently hold about you.
      </Typography>
      <Typography>
        In order to receive such information please send your contact details
        including address and payment of £10 to cover administration expenses to
        the following address:
      </Typography>
      <Typography><br/>C
        The Techno Centre<br/>Coventry University Business Park<br/>Puma Way<br/>Coventry
        <br/>West Midlands <br/>CV1 2TT
      </Typography>
      <Typography sx={{ fontSize: "1.1rem", fontWeight: 500, mt: 2, mb: 1 }}>
        Other Websites
      </Typography>
      <Typography>
        This privacy policy only covers this website. Any other websites which
        may be linked to by our website are subject to their own policy, which
        may differ from ours.
      </Typography>
    </>
  );
}

export default SnaggingPrivacyPolicy;
